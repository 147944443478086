import Swiper, { Autoplay, Navigation, Thumbs } from 'swiper';

function imagesC() {
	Swiper.use([Autoplay, Navigation, Thumbs]);

	let thumbsImagesC = new Swiper('.js-slider-swiper-images-c-thumbs', {
		loop: true,
		spaceBetween: 15,
		slidesPerView: 3,
		watchSlidesProgress: true,
		breakpoints: {
			544: {
				slidesPerView: 4,
			},
			768: {
				slidesPerView: 5,
			},
		},
	});

	new Swiper('.js-slider-swiper-images-c', {
		loop: false,
		speed: 600,
		autoplay: false,
		spaceBetween: 15,
		grabCursor: true,
		navigation: {
			prevEl: '.js-slider-swiper-images-c-btn-prev',
			nextEl: '.js-slider-swiper-images-c-btn-next',
		},
		thumbs: {
			swiper: thumbsImagesC,
		},
	});
}

export default imagesC;
