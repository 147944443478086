/*
 * Slide out
 *
 * runs the functionality of functions inside the slideout.
 * The actual opening and closing of the slide-out is handled in hamburger.js
 */

let menuItem = '.js-menu-item';
let activeMenuClass = 'm-slide-out__nav-menu--active';

export function slideOut() {
	// add for active on hover
	$(menuItem).hover(
		function() {
			$(menuItem).removeClass(activeMenuClass);
			$(this).addClass(activeMenuClass);
		},
		function() {
			$(menuItem).removeClass(activeMenuClass);
		},
	);

	// Get the navigation links without children
	const navLinks = $('.current-menu-item');

	// Add a click event listener to each link
	navLinks.on('click', function() {
		// Get the slide-out navigation menu
		const navMenu = $('.m-slide-out');

		// Remove the active class from the navigation menu and the scroll lock class from the body
		navMenu.removeClass('m-slide-out--active');
		$('body').removeClass('h-scroll-lock');
		$('body').removeClass('nav-active');
		$('.js-hamburger').removeClass('is-active');
	});
}
