function gallery() {
	if ($('.js-gallery').length > 0) {
		$('.js-gallery').lightGallery({
			selector: '.js-gallery-img',
			thumbnail: true,
			animateThumb: true,
			showThumbByDefault: true,
			mode: 'lg-slide',
		});
	}
}

export default gallery;
