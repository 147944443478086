import Swiper, { Autoplay, Navigation } from 'swiper';

function sliderCardsD() {
	Swiper.use([Autoplay, Navigation]);

	new Swiper('.o-cards-d-1__slider-container', {
		loop: false,
		speed: 600,
		autoplay: false,
		slidesPerView: 1,
		spaceBetween: 18,
		navigation: {
			prevEl: '.js-slider-btn-prev',
			nextEl: '.js-slider-btn-next',
		},
		breakpoints: {
			768: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
			},
		},
	});
}

export default sliderCardsD;
