function cardImageText5() {
	/* --- Show membership features on Learn More click --- */
	$('.m-card-image-text-5__learn-more').on('click', function(e) {
		e.preventDefault();
		const footerSelectedItemContent = $(this).next(
			'.m-card-image-text-5__dropdown',
		);
		$(this)
			.children('i')
			.toggleClass('fa-caret-down fa-caret-up');
		footerSelectedItemContent.slideToggle(400);
	});
	/* --- End of Learn More logic --- */
}

export default cardImageText5;
