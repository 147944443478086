function breadcrumbs() {
	// if (
	// 	$('a.taxonomy.neighborhood ').find(
	// 		'span:contains(' + $('.post-location.current-item').text() + ')',
	// 	)
	// ) {
	// 	// eslint-disable-next-line jquery/no-hide
	// 	$('.post-location.current-item').hide();
	// 	// eslint-disable-next-line jquery/no-hide
	// 	$('.m-breadcrumbs .fa-angle-right')
	// 		.last()
	// 		.hide();
	// }
}

export default breadcrumbs;
