class MapA1 {
	SCRIPT_ID = 'google-map-a-1-script';

	/**
	 * Block Container
	 * @type {HTMLElement}
	 */
	container;

	/**
	 * Google Map API key
	 * @type {String}
	 */
	googleApiKey = '';

	/**
	 * Map Block
	 * @type {HTMLElement}
	 */
	mapBlock;

	/**
	 * HTML elements for locations
	 * @type {HTMLCollection}
	 */
	locationsElements;

	/**
	 * Array of all the locations which should be shown on the map
	 * @type {Array}
	 */
	locations = [];

	/**
	 * Google Map zoom level value
	 * @type {Number}
	 */
	zoom;

	/**
	 * Path to custom marker image icon
	 * @type {String}
	 */
	markerImageSrc = '';

	/**
	 * Path to custom marker image icon for active marker state
	 * @type {String}
	 */
	activeMarkerImageSrc = '';

	/**
	 * Google Map instance
	 * @type {Map}
	 */
	map;

	/**
	 * Google Map bounds
	 * @type {Object}
	 */
	bounds;

	/**
	 * An array of markers
	 * @type {Array}
	 */
	markers = [];

	/**
	 * Index of the currently active (with opened InfoWindow) marker
	 * @type {Number}
	 */
	activeMarker = null;

	constructor(container) {
		// Store the container element
		this.container = container;
		// Retrieve the Google API key from the container's data attribute
		this.googleApiKey = this.container.dataset.key ?? '';
		// Get the map block element within the container
		this.mapBlock = this.container.querySelector('.js-map');
		// Get all location elements within the container
		this.locationsElements = this.container.querySelectorAll(
			'.js-location',
		);
		// Retrieve the marker image source from the container's data attribute
		this.markerImageSrc = this.container.dataset.marker ?? '';
		// Retrieve the active marker image source from the container's data attribute
		this.activeMarkerImageSrc = this.container.dataset.activeMarker ?? '';
		// Zoom
		if (this.container.dataset.zoom) {
			this.zoom = parseInt(this.container.dataset.zoom, 10);
		}
		// Initialize the map
		this.init();
	}

	/**
	 * Init Google Map block
	 */
	async init() {
		// Set locations based on the data attributes of location elements
		this.setLocations();
		// Bind the 'initMap' method to the current instance of the class
		window.initMap = this.initMap.bind(this);
		// Load necessary scripts and libraries asynchronously
		await this.addScripts();
	}

	/**
	 * Set locations based on the data attributes of location elements
	 */
	setLocations() {
		this.locationsElements.forEach(location => {
			// Create an object for each location with its properties
			this.locations.push({
				name: location.dataset.name,
				address: location.dataset.address,
				lat: parseFloat(location.dataset.lat),
				lng: parseFloat(location.dataset.lng),
				neighborhoods: location.dataset.neighborhoods,
				url: location.dataset.url,
			});
		});
	}

	/**
	 * Load necessary scripts and libraries asynchronously
	 */
	addScripts() {
		return new Promise((resolve, reject) => {
			const existingScript = document.getElementById(MapA1.SCRIPT_ID);
			if (existingScript) {
				resolve();
			}

			// Create a script element
			const script = document.createElement('script');
			script.id = MapA1.SCRIPT_ID;

			// Set the script source with the API key and necessary libraries
			/* eslint-disable */
			script.src = `https://maps.googleapis.com/maps/api/js?key=${this.googleApiKey}&callback=initMap&libraries=places,geometry`;
			/* eslint-enable */
			// Set the async and defer attributes
			script.async = true;
			script.defer = true;

			script.onload = resolve;
			script.onerror = reject;

			// Append the script to the document's head
			document.head.appendChild(script);
		});
	}

	/**
	 * Initialize the map using the Google Maps API
	 */
	initMap() {
		const google = window.google;
		// Create a new map object with specified options
		this.map = new google.maps.Map(this.mapBlock, {
			mapTypeId: google.maps.MapTypeId.ROADMAP,
			scrollwheel: false,
			streetViewControl: false,
			styles: [
				{
					elementType: 'geometry',
					stylers: [
						{
							color: '#f5f5f5',
						},
					],
				},
				{
					elementType: 'labels.icon',
					stylers: [
						{
							visibility: 'off',
						},
					],
				},
				{
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#616161',
						},
					],
				},
				{
					elementType: 'labels.text.stroke',
					stylers: [
						{
							color: '#f5f5f5',
						},
					],
				},
				{
					featureType: 'administrative.land_parcel',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#bdbdbd',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'geometry',
					stylers: [
						{
							color: '#eeeeee',
						},
					],
				},
				{
					featureType: 'poi',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#757575',
						},
					],
				},
				{
					featureType: 'poi.park',
					elementType: 'geometry',
					stylers: [
						{
							color: '#e5e5e5',
						},
					],
				},
				{
					featureType: 'poi.park',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#9e9e9e',
						},
					],
				},
				{
					featureType: 'road',
					elementType: 'geometry',
					stylers: [
						{
							color: '#ffffff',
						},
					],
				},
				{
					featureType: 'road.arterial',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#757575',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'geometry',
					stylers: [
						{
							color: '#dadada',
						},
					],
				},
				{
					featureType: 'road.highway',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#616161',
						},
					],
				},
				{
					featureType: 'road.local',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#9e9e9e',
						},
					],
				},
				{
					featureType: 'transit.line',
					elementType: 'geometry',
					stylers: [
						{
							color: '#e5e5e5',
						},
					],
				},
				{
					featureType: 'transit.station',
					elementType: 'geometry',
					stylers: [
						{
							color: '#eeeeee',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'geometry',
					stylers: [
						{
							color: '#c9c9c9',
						},
					],
				},
				{
					featureType: 'water',
					elementType: 'labels.text.fill',
					stylers: [
						{
							color: '#9e9e9e',
						},
					],
				},
			],
		});

		// Set needed zoom value
		if (this.zoom && this.zoom > 0) {
			google.maps.event.addListenerOnce(
				this.map,
				'bounds_changed',
				() => {
					this.map.setZoom(this.zoom);
				},
			);
		}

		// Create bounds object for fitting all markers
		this.bounds = new google.maps.LatLngBounds();

		let markerArgs = {
			map: this.map,
		};
		if (this.markerImageSrc) {
			// Set custom marker image source if provided
			markerArgs.icon = this.markerImageSrc;
		}

		// Balloon instance
		let infoWindow = new google.maps.InfoWindow();

		// Changing marker icon on info window open event
		google.maps.event.addListener(infoWindow, 'domready', () => {
			if (this.activeMarkerImageSrc && this.activeMarker !== null) {
				let activeMarker = this.markers[this.activeMarker];
				if (activeMarker) {
					activeMarker.setIcon(this.activeMarkerImageSrc);
				}
			}
		});
		// Changing marker icon on info window close event
		google.maps.event.addListener(infoWindow, 'closeclick', () => {
			if (this.activeMarker !== null) {
				let activeMarker = this.markers[this.activeMarker];
				if (activeMarker) {
					if (this.markerImageSrc) {
						activeMarker.setIcon(this.markerImageSrc);
					} else {
						activeMarker.setIcon();
					}
				}
				this.activeMarker = null;
			}
		});

		// Add markers for each location
		this.locations.forEach((location, index) => {
			const currentMarkerArgs = {
				...markerArgs,
				position: new google.maps.LatLng(location.lat, location.lng),
				title: location.name,
			};
			const marker = new google.maps.Marker(currentMarkerArgs);
			this.markers.push(marker);
			this.bounds.extend(marker.getPosition());

			//click on pin
			google.maps.event.addListener(marker, 'click', () => {
				let content = '';
				if (location.name) {
					content += `<h4 class="text-center mb-2.5 pb-0 a-title--style-heading-06 font-bold font-circular">${location.name}</h4>`;
				}
				if (location.address) {
					content += `<p class="mb-2.5"><strong>Address:</strong> ${location.address}</p>`;
				}
				if (location.url) {
					content += `<a href="${location.url}" target="_blank" class="a-btn a-btn--style-open-type-01 !py-2 !px-2 block mt-2 font-bold text-center mx-auto !min-w-full">Learn more</a>`;
				}
				if (content) {
					infoWindow.setContent(
						`<div class="p-1.5">${content}</div>`,
					);
					infoWindow.open(this.map, marker);
					this.activeMarker = index;
				}
			});
		});

		// Autocenter the map
		this.map.fitBounds(this.bounds);
	}
}

function mapA1() {
	const maps = document.querySelectorAll('.js-map-a-1-container');
	maps.forEach(container => {
		new MapA1(container);
	});
}

export default mapA1;
