import Swiper, { Autoplay, Navigation } from 'swiper';

function images() {
	Swiper.use([Autoplay, Navigation]);

	new Swiper('.js-slider-swiper-images', {
		loop: false,
		speed: 600,
		autoplay: false,
		slidesPerView: 1,
		spaceBetween: 48,
		navigation: {
			prevEl: '.js-slider-swiper-images-btn-prev',
			nextEl: '.js-slider-swiper-images-btn-next',
		},
	});
}

export default images;
