import { gsap, Power3, TweenMax } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import SplitText from '../vendors/gsap-splittext/splittext';

function letterEffects() {
	gsap.registerPlugin(ScrollTrigger);

	// Assign variable to the element that will be animated.
	const element = $('.js-anim-split-text');

	// Checking if the element node (a div etc) has data-tags which serve as animation parameters
	console.log($(element).data());

	// Checking if the element exists on the page. If so, proceed.
	if (element.length) {
		// Making an array of elements. As multiple (to-be animated) elements could exists on a page.
		const elements = gsap.utils.toArray('.js-anim-split-text');

		// Looping through the array. Firing a timeline for each
		elements.forEach(item => {
			let tl = gsap.timeline({
					delay: 0,
					onStart: () => {
						TweenMax.set(item, {
							visibility: 'visible',
						});
					},
					scrollTrigger: {
						trigger: item,
						toggleActions: 'restart none none reset',
						end: 'bottom center',
						markers: true,
					},
				}),
				// Creating a splittext object for each letter/word
				elementSplitted = new SplitText(item, {
					type: 'chars,words',
				}),
				elementWords = elementSplitted.chars;

			// We're finally ready to animate! Staggering each letter or word
			tl.staggerFrom(
				elementWords,
				1.0,
				{
					y: 10,
					autoAlpha: 0,
					rotation: 1,
					ease: Power3.easeOut,
				},
				0.05,
				'+=0',
			);
		});
	}
}

export default letterEffects;
