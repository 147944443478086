import Swiper, { Autoplay, Navigation } from 'swiper';

function sliderLatestPosts() {
	Swiper.use([Autoplay, Navigation]);

	new Swiper('.o-latest-posts-1__slider-container', {
		loop: false,
		speed: 600,
		autoplay: false,
		grabCursor: true,
		slidesPerView: 1,
		spaceBetween: 0,
		navigation: {
			prevEl: '.js-slider-btn-prev',
			nextEl: '.js-slider-btn-next',
		},
		breakpoints: {
			640: {
				slidesPerView: 2,
			},
			1024: {
				slidesPerView: 3,
				spaceBetween: 0,
			},
			1280: {
				slidesPerView: 3,
				spaceBetween: 0,
			},
		},
	});
}

export default sliderLatestPosts;
