/*
 * Nav Dropdown
 *
 * runs the functionality of clicking the menu item with submenus
 */
function clickActionOutsideArea(e) {
	if (
		$(e.target).is($('a.menu-item-has-children')) === false &&
		$('a.menu-item-has-children').has(e.target).length === 0 &&
		$(e.target).is($('.m-nav-dropdown')) === false &&
		$('.m-nav-dropdown').has(e.target).length === 0
	) {
		$('.o-header-1').removeClass('o-header-1--dropdown--active');
		$('.o-header-1__dropdown-bg').css('height', '0');
		$('.m-nav-dropdown__separator-line').css('height', '0');
		$('.m-nav-dropdown').removeClass('m-nav-dropdown--active');
		$('.m-nav__link--level-0').removeClass('m-nav__link--level-0-active');
	}
}

function navDropdown() {
	$('.m-nav__link--level-0.menu-item-has-children').on('click', function(e) {
		e.preventDefault();

		$('.o-header-1').removeClass('o-header-1--dropdown--active');
		$('.o-header-1__dropdown-bg').css('height', '0');
		$('.m-nav-dropdown__separator-line').css('height', '0');

		$('.m-nav__link--level-0')
			.not(this)
			.removeClass('m-nav__link--level-0-active');
		$(this).toggleClass('m-nav__link--level-0-active');

		let sibling = $(this).siblings('.m-nav-dropdown');

		$('.m-nav-dropdown')
			.not(sibling)
			.removeClass('m-nav-dropdown--active');

		$(this)
			.siblings('.m-nav-dropdown')
			.toggleClass('m-nav-dropdown--active');

		$('.o-header-1__dropdown-bg').css(
			'height',
			$('.m-nav-dropdown--active').height() + 'px',
		);

		$('.m-nav-dropdown__separator-line').css(
			'height',
			$('.m-nav-dropdown--active').height() + 'px',
		);
	});

	$('.m-nav-dropdown__link--level-1.menu-item-has-children').on(
		'click',
		function(e) {
			e.preventDefault();

			let dataImgTarget = $(this).attr('data-img-target');

			$(
				'.m-nav-dropdown--active .m-nav-dropdown__sub-menu-img',
			).removeClass('m-nav-dropdown__sub-menu-img--active');

			$('.m-nav-dropdown--active')
				.find(`[data-img-index = '${dataImgTarget}']`)
				.toggleClass('m-nav-dropdown__sub-menu-img--active');

			$('.m-nav-dropdown--active .m-nav-dropdown__link--level-1')
				.not(this)
				.removeClass('m-nav-dropdown__link--level-1-active');
			$(this).addClass('m-nav-dropdown__link--level-1-active');

			let sibling = $(this)
				.parent('.m-nav-dropdown__item--level-1')
				.next('.m-nav-dropdown__sub-menu');

			$('.m-nav-dropdown--active .m-nav-dropdown__sub-menu')
				.not(sibling)
				.removeClass('m-nav-dropdown__sub-menu--active');

			$(this)
				.parent('.m-nav-dropdown__item--level-1')
				.next('.m-nav-dropdown__sub-menu')
				.addClass('m-nav-dropdown__sub-menu--active');

			$(this)
				.closest('.m-nav-dropdown__sub-menu--level-0')
				.next('.m-nav-dropdown__sub-content')
				.removeClass('m-nav-dropdown__sub-content--active');
			$(this)
				.closest('.m-nav-dropdown__sub-menu--level-0')
				.children('.m-nav-dropdown__sub-menu-title--active')
				.removeClass('m-nav-dropdown__sub-menu-title--active');
		},
	);
	$('.m-nav-dropdown__sub-menu-title-link').on('click', function(e) {
		e.preventDefault();
		let dataImgTarget = $(this).attr('data-img-target');

		$('.m-nav-dropdown--active .m-nav-dropdown__sub-menu-img').removeClass(
			'm-nav-dropdown__sub-menu-img--active',
		);

		$('.m-nav-dropdown--active')
			.find(`[data-img-index = '${dataImgTarget}']`)
			.toggleClass('m-nav-dropdown__sub-menu-img--active');

		$(this)
			.parent('.m-nav-dropdown__sub-menu-title')
			.addClass('m-nav-dropdown__sub-menu-title--active');
		$(this)
			.parent('.m-nav-dropdown__sub-menu-title')
			.parent('.m-nav-dropdown__sub-menu ')
			.next('.m-nav-dropdown__sub-content')
			.addClass('m-nav-dropdown__sub-content--active');
		$(
			'.m-nav-dropdown--active  .m-nav-dropdown__sub-menu--active',
		).removeClass('m-nav-dropdown__sub-menu--active');
		$(
			'.m-nav-dropdown--active .m-nav-dropdown__link--level-1-active',
		).removeClass('m-nav-dropdown__link--level-1-active');
	});
	$(document).on('click', function(e) {
		if ($('.m-nav-dropdown').hasClass('m-nav-dropdown--active')) {
			clickActionOutsideArea(e);
		}
		if ($('.m-nav__link--level-0-active').length > 0) {
			$('.o-header-1').addClass('o-header-1--dropdown--active');
		}
	});

	$(document).ready(function() {
		if (
			$('.m-nav-dropdown__item--level-1.current-menu-parent').length > 0
		) {
			$('.m-nav-dropdown--active .m-nav-dropdown__sub-menu').removeClass(
				'm-nav-dropdown__sub-menu--active',
			);
			$(
				'.m-nav-dropdown--active .m-nav-dropdown__sub-menu-img',
			).removeClass('m-nav-dropdown__sub-menu-img--active');

			let currentItem;
			currentItem = $(
				'.m-nav-dropdown__item--level-1.current-menu-parent',
			);
			currentItem
				.closest('.m-nav-dropdown')
				.find('.m-nav-dropdown__sub-content')
				.removeClass('m-nav-dropdown__sub-content--active');
			currentItem
				.closest('.m-nav-dropdown')
				.find('.m-nav-dropdown__sub-menu-title')
				.removeClass('m-nav-dropdown__sub-menu-title--active');
			currentItem
				.closest('.m-nav-dropdown')
				.find('.m-nav-dropdown__sub-menu-img--index-0')
				.removeClass('m-nav-dropdown__sub-menu-img--active');
			currentItem
				.next('.m-nav-dropdown__sub-menu')
				.addClass('m-nav-dropdown__sub-menu--active');

			let dataImgTarget = $(
				'.m-nav-dropdown__link--level-1.current-menu-parent',
			).attr('data-img-target');

			currentItem
				.closest('.m-nav-dropdown')
				.find(`[data-img-index = '${dataImgTarget}']`)
				.addClass('m-nav-dropdown__sub-menu-img--active');
		}
	});

	$(window).resize(function() {
		$('.o-header-1__dropdown-bg').css(
			'height',
			$('.m-nav-dropdown--active').height() + 'px',
		);

		$('.m-nav-dropdown__separator-line').css(
			'height',
			$('.m-nav-dropdown--active').height() + 'px',
		);
	});
}

export default navDropdown;
