import Swiper, { Autoplay, Navigation } from 'swiper';

function imagesB() {
	Swiper.use([Autoplay, Navigation]);

	new Swiper('.js-slider-swiper-images-b', {
		loop: false,
		speed: 600,
		autoplay: false,
		slidesPerView: 1,
		spaceBetween: 30,
		grabCursor: true,
		navigation: {
			prevEl: '.js-slider-swiper-images-b-btn-prev',
			nextEl: '.js-slider-swiper-images-b-btn-next',
		},
	});
}

export default imagesB;
