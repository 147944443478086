import './util/modernizr';
import './util/polyfills';
import '../style.scss';
import './core/core';

/*
 * Atoms
 */
import floatingCta from '../patterns/01-atoms/buttons/button-floating-cta/button-floating-cta';
import scrollToTop from '../patterns/01-atoms/misc/scroll-to-top/scroll-to-top';
/*
 * Molecules
 */
import showAccordion from '../patterns/02-molecules/accordions/accordion/accordion';
import dropdown from '../patterns/02-molecules/dropdowns/dropdown/dropdown';
import gallery from '../patterns/02-molecules/galleries/gallery/gallery';
import cardImageText5 from '../patterns/02-molecules/cards/card-image-text-5/card-image-text-5';
import mapBlock from '../patterns/02-molecules/maps/map/map';
import modal from '../patterns/02-molecules/modals/modal/modal';
import { hamburger } from '../patterns/02-molecules/nav/hamburger/hamburger';
import navigateCategory from '../patterns/02-molecules/nav/nav-category/nav-category';
import navMob from '../patterns/02-molecules/nav/nav-mobile/nav-mobile';
import searchBar from '../patterns/02-molecules/search/search-bar/search-bar';
import { slideOut } from '../patterns/02-molecules/slide-outs/slide-out/slide-out';
import sliderTestimonials from '../patterns/02-molecules/sliders/slider-testimonials/slider-testimonials';
import socialShare from '../patterns/02-molecules/social/social-share/social-share';
import tabs from '../patterns/02-molecules/tabs/tabs/tabs';
import multiselectDropdown from '../patterns/02-molecules/dropdowns/terms-multiselect/terms-multiselect';
import navDropdown from '../patterns/02-molecules/nav/nav-dropdown/nav-dropdown';
import sidebar from '../patterns/02-molecules/sidebar/sidebar';

/*
 * Organisms
 */
import header from '../patterns/03-organisms/header/header-1/header-1';
import parallaxScroll from './animations/parallax-light';
import filterType1 from '../patterns/03-organisms/repeatable-blocks/filter/filter-1/filter-1';
import sliderLatestPosts from '../patterns/03-organisms/repeatable-blocks/latest-posts/latest-posts-1/latest-posts-1.js';
import images from '../patterns/03-organisms/repeatable-blocks/images/images-1/images-1';
import imagesB from '../patterns/03-organisms/repeatable-blocks-inner/images-b/images-b-1/images-b-1';
import imagesC from '../patterns/03-organisms/repeatable-blocks-inner/images-c/images-c-1/images-c-1';
import sliderCardsD from '../patterns/03-organisms/repeatable-blocks/cards-d/cards-d-1/cards-d-1.js';
import breadcrumbs from '../patterns/03-organisms/breadcrumbs/breadcrumbs-1/breadcrumbs-1';
import mapA1 from '../patterns/03-organisms/repeatable-blocks/map-a/map-a-1/map-a-1';
/*
 * Animations
 */
// import scrollSkewer from './animations/scroll-skewer';
import letterEffects from './animations/splittext';
import cursorEffects from './animations/cursor-effects';
/*
 * Misc
 */
import scrollToAnchor from './misc/scroll/scroll-to-anchor';
import externalLinks from './misc/external-links/external-links';

/*
 * Plop Entry (do not remove)
 */
////DO NOT ERASE OR PLOP IMPORTING WILL FAIL
////IMPORT FROM PLOP

/*
 * Module Caller
 *
 * functions below are only run if the component exists (verified through a "data-module" in the html)
 * in the ajax container or page. Barba.js instantiates the functions below on container reload as well.
 *
 * Place in alphabetical order
 */
const modules = {
	showAccordion,
	images,
	imagesB,
	imagesC,
	sliderTestimonials,
	sliderLatestPosts,
	header,
	navigateCategory,
	searchBar,
	scrollToTop,
	floatingCta,
	socialShare,
	hamburger,
	slideOut,
	modal,
	tabs,
	mapBlock,
	dropdown,
	gallery,
	filterType1,
	multiselectDropdown,
	cardImageText5,
	sliderCardsD,
	navDropdown,
	sidebar,
	breadcrumbs,
	mapA1,
};

/*
 * Fire JavaScript functions by if the associated "data-module" exists on the page.
 */
let calledModulesNames = [];
modules.instantiate = function(elem) {
	const $this = $(elem);
	const module = $this.attr('data-module');

	if (module === undefined) {
		throw 'Module not defined (use data-module="")';
	} else if (module in modules) {
		if ($this.attr('data-initialized') === 'true') {
			return;
		}
		new modules[module](elem);
		$this.attr('data-initialized', true);
	} else {
		throw "Module '" + module + "' not found";
	}
};

jQuery(document).ready(function() {
	scrollToAnchor($);
	navMob($);
	externalLinks($);

	/*
	 * Loops through component functions that should only run if the component exists in the ajax container or page.
	 * The actual check is performed through "data-module";
	 */
	$('[data-module]').each(function() {
		if ($.inArray($(this).data('module'), calledModulesNames) == -1) {
			modules.instantiate(this);
			calledModulesNames.push($(this).data('module'));
		}
	});

	/*
	 * GSAP based animations. Hide if not used.
	 */
	// cursorEffects($);
	// letterEffects($);
	// scrollSkewer($);
});

jQuery(window).scroll(function() {
	parallaxScroll($);
});
