function sidebar() {
	$('#select_action .a-btn').on('click', function(e) {
		if (!$(this).hasClass('external')) {
			e.preventDefault();
			let containerToShow = $(this).attr('href');
			// eslint-disable-next-line jquery/no-hide
			$('#select_action').hide();
			// eslint-disable-next-line jquery/no-show
			$(containerToShow).show();
			// eslint-disable-next-line jquery/no-show
			$('#get_in_touch').show();
		}
	});

	$('.back_to_actions').on('click', function(e) {
		e.preventDefault();
		// eslint-disable-next-line jquery/no-hide
		$('#hubspot_form').hide();
		// eslint-disable-next-line jquery/no-hide
		$('#calendly_form').hide();
		// eslint-disable-next-line jquery/no-hide
		$('#get_in_touch').hide();
		// eslint-disable-next-line jquery/no-show
		$('#select_action').show();
	});
}

export default sidebar;
