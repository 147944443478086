function multiselectDropdown() {
	// Dropdown Container
	const containers = document.querySelectorAll('.m-terms-multiselect');
	if (!containers.length) {
		return;
	}
	// Looping through all of them (we can have more than 1 on a page)
	[...containers].map(container => {
		// Dropdowns
		const select = container.querySelector(
			`select[data-role='multiselect-dropdown']`,
		);
		if (select) {
			$(select).multiselect({
				columns: select.dataset.placeholder
					? parseInt(select.dataset.placeholder)
					: 1,
				search: parseInt(select.dataset.search) === 1,
				selectAll:
					parseInt(select.getAttribute('data-select-all')) === 1,
				showCheckbox: parseInt(select.dataset.checkbox) === 1,
				texts: {
					placeholder: select.dataset.placeholder,
					selectAll: select.getAttribute('data-select-all-text'),
				},
			});
		}

		// Clear Button
		const clearButton = container.querySelector(
			`[data-role='dropdown-clear']`,
		);
		if (clearButton) {
			clearButton.addEventListener('click', event => {
				// Preventing default behaviour
				event.preventDefault();

				// Related Dropdown
				const relatedDropdown = select;
				if (!relatedDropdown) {
					return false;
				}
				// All the options
				const options = relatedDropdown.querySelectorAll('option');
				if (!options.length) {
					return false;
				}
				// Unselect all the options
				[...options].map(option => {
					option.selected = false;
				});
				// Trigger change event on this select
				relatedDropdown.dispatchEvent(new Event('change'));
				// Re-render multiselect element
				$(relatedDropdown).multiselect('reload');
			});
		}
	});
}

export default multiselectDropdown;
